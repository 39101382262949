<template>
  <div>
    <template v-if="!loading">
      <top-ten-item-card v-for="row in data" :row="row" :key="row.idRanking" @click="selectProduct(row)" :date="date" />
    </template>
    <template v-else>
      <span v-for="i in 10" :key="i" class="h-20 rounded-lg border-2 border-white border-solid mb-1 skeleton bg-secondary-100 w-full block"></span>
    </template>
  </div>
</template>

<script>
import TopTenItemCard from '@/components/TopTenItemCard.vue'

export default {
  name: 'TopTenCardView',
  props: {
    data: {
      type: Array,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TopTenItemCard
  },
  methods: {
    selectProduct: function (product) {
      this.$emit('selected', product)
    }
  }
}
</script>

<style lang="scss" scoped>
.skeleton {
  position: relative;
  overflow: hidden;
  background-color: #e2e8f0;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
</style>
