<template>
  <div
    class="card bg-white rounded-lg flex items-center mb-3 transition-all duration-150 shadow hover:shadow-lg border-2 border-solid border-transparent hover:border-primary"
  >
    <div class="w-20 bg-white flex-shrink-0 p-1 rounded-lg relative flex items-center justify-center">
      <img
        class="w-full object-contain max-h-20"
        :src="row.imageurl"
        :alt="row.name"
      >
    </div>
    <div
      class="flex items-center w-full"
    >
      <span class="block relative px-2 flex-shrink-0">
        <b
          v-if="setPositionIcon"
          :class="setPositionStatus"
          class="absolute -top-1 right-0 w-4 h-4 bg-white flex items-center justify-center z-10 rounded-full"
        >
          <base-icon :name="setPositionIcon" />
        </b>
        <span
          :class="setPostionStatusNumber"
          class="h-9 w-9 flex items-center justify-center rounded-full font-bold text-lg"
        >{{ position }}</span>
      </span>
      <div @click="onClick(row)" class="pl-3 py-3 pr-4 overflow-hidden flex-grow w-60 relative z-10 product-name">
        <span class="text-xs uppercase font-semibold opacity-75">{{ row.brandname }}</span>
        <h3 class="font-medium truncate leading-tight">
          {{ row.name }}
          <a
            :href="row.link"
            target="_blank"
            alt="Ficha del producto en su web de venta. Se abrirá en página a parte"
            class="tr-external-link"
            v-tooltip="'Ver ficha - Enlace Externo'"
          >
            <base-icon
              name="external"
              size="xs"
            />
          </a>
        </h3>
      </div>
      <div class="flex-shrink-0 px-4">
        <span
        :class="setPriceStatus"
        class="flex items-center justify-center font-semibold h-7 rounded-lg px-2">
        <base-icon v-if="setPriceIcon" :name="setPriceIcon" size="xs" />
        <span class="font-mono">{{ row.pvp | money(countryCode) }}</span>
      </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'top-ten-item-card',
  props: {
    row: {
      type: Object,
      required: true
    },
    date: {
      type: String,
      required: true
    }
  },
  computed: {
    countryCode: function () {
      return this.$store.getters['filters/countryCode']
    },
    position: function () {
      return this.row.position
    },
    priceVariation: function () {
      return this.row.pvpchange
    },
    setPriceStatus: function () {
      if (this.row.pvpchange > 0) {
        return 'bg-success-50 text-success'
      } else if (this.row.pvpchange < 0) {
        return 'bg-danger-50 text-danger'
      } else {
        return 'bg-secondary-50'
      }
    },
    setPriceIcon: function () {
      if (this.row.pvpchange > 0) {
        return 'priceup'
      } else if (this.row.pvpchange < 0) {
        return 'pricedown'
      } else {
        return false
      }
    },
    setPostionStatusNumber: function () {
      if (this.row.positionchange < 0) {
        return 'bg-success-50 text-success'
      } else if (this.row.positionchange > 0) {
        return 'bg-danger-50 text-danger'
      } else {
        return 'bg-secondary-50'
      }
    },
    setPositionStatus: function () {
      if (this.row.positionchange < 0) {
        return 'text-success'
      } else if (this.row.positionchange > 0) {
        return 'text-danger'
      } else {
        return 'text-primary'
      }
    },
    setPositionIcon: function () {
      const enteredAt = this.row.enteredat
      const createdAt = this.row.createdAt
      const date = this.date

      if (createdAt === date) {
        return 'nentry'
      }
      if (enteredAt === date) {
        return 'rentry'
      }
      if (this.row.positionchange < 0) {
        return 'posup'
      }
      if (this.row.positionchange > 0) {
        return 'posdown'
      }

      return false
    }
  },
  methods: {
    onClick: function (product) {
      this.$emit('click', product)
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  .size-data {
    min-width: 60px;
  }
  .select-item {
    visibility: hidden;
  }
  &:hover {
    .select-item {
      visibility: visible;
    }
  }
}
.card-progress {
  height: calc(100% - 8px);
}

.tr-external-link {
  opacity: 0;
  position: absolute;
  top: 50%;
  background: var(--primary-700);
  border-radius: 50%;
  transform: translateY(-50%);
  right: -10px;
  color: white;
  transition: right 0.2s, opacity 0.3s;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.product-name {
  cursor: pointer;
  &:hover {
    .tr-external-link {
      opacity: 1;
      right: 4px;
      &:hover {
        background: darken(#086dff, 10%);
      }
    }
  }
}

input[type="checkbox"].check-custom:hover ~ .check-toggle {
  @apply border-2 border-solid border-primary;
}

/* Styles for the focus state appearance of the custom checkbox */
input[type="checkbox"].check-custom:focus ~ .check-toggle {
  border-color: #b0d5ff;
  box-shadow: 0 0 0 2px rgba(23, 133, 255, 0.25);
}

input[type="checkbox"].check-custom {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

input[type="checkbox"].check-custom ~ .check-toggle {
  width: 1.1rem;
  height: 1.1rem;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  cursor: pointer;
  background: white;
  @apply border-2 border-solid border-primary;
}

/* Styles for the hover state appearance of the custom checkbox */
input[type="checkbox"].check-custom:hover ~ .check-toggle {
  @apply border-2 border-solid border-primary-500;
}

/* Styles for the checked state appearance of the custom checkbox */
input[type="checkbox"].check-custom:checked ~ .check-toggle {
  @apply bg-primary border-2 border-solid border-primary;
  background: var(--primary-700)
    url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cG9seWxpbmUgcG9pbnRzPSIyMCA2IDkgMTcgNCAxMiI+PC9wb2x5bGluZT48L3N2Zz4=)
    center no-repeat;
  background-size: 80%;
}

input[type="checkbox"].check-custom:checked ~ .check-toggle {
  visibility: visible;
}
</style>
