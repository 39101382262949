import axiosInstance from '@/services/axiosInstance'

async function getCompetition (startDate, endDate, data) {
  const req = {
    url: `visualRank/competition/${startDate}/${endDate}`,
    method: 'POST',
    data
  }

  const res = await axiosInstance(req)

  return res.data
}

export { getCompetition }
