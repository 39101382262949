<template>
  <div>
    <table v-if="!loading">
      <thead>
        <tr>
          <th></th>
          <th>{{ $t('app.brand') }}</th>
          <th>{{ $t('app.model') }}</th>
          <th>{{ $t('app.price') }}</th>
        </tr>
      </thead>
      <tbody>
        <table-row-top-ten v-for="row in data" :row="row" :key="row.idRanking" :date="date" @click="selectProduct(row)" />
      </tbody>
    </table>
    <template v-else>
      <span v-for="i in 10" :key="i" class="h-10 rounded-lg border-2 border-white border-solid mb-1 skeleton bg-secondary-100 w-full block"></span>
    </template>
  </div>
</template>

<script>
import TableRowTopTen from '@/components/tables/TableRowTopTen.vue'

export default {
  name: 'TableTop10',
  components: {
    TableRowTopTen
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    selectProduct: function (product) {
      this.$emit('selected', product)
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border: none;
  position: relative;
  // overflow: hidden;
  background-color: transparent;
  border-collapse: separate;
  border-spacing: 0 4px;
  margin-top: -4px; /* correct offset on first border spacing if desired */
}

thead {
  tr th {
    @apply text-secondary-500;
    padding: 4px 8px;
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 500;
    text-align: left;
  }
}

th {
  @apply sticky top-0 z-20 bg-primary-body;
}

.skeleton {
  position: relative;
  overflow: hidden;
  background-color: #e2e8f0;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

</style>
