import axiosInstance from '@/services/axiosInstance'
import store from '@/store'
import moment from 'moment'

async function getTop10 (groupId, categoryId) {
  const date = store.getters['filters/general']?.dateRange?.end
  const req = {
    url: 'dashboard/top10/' + groupId + '/' + categoryId + '/' + moment(date).format('DD-MM-YYYY'),
    method: 'GET'
  }

  const res = await axiosInstance(req)

  return res.data
}

export { getTop10 }
