<template>
  <div class="flex flex-col h-full flex-grow overflow-hidden">
    <nav class="flex items-center justify-center mb-4 flex-shrink-0 mt-4">
      <ul class="grid grid-cols-2 bg-white shadow rounded-lg h-20 divide-x overflow-hidden">
        <li
          :class="{'bg-secondary text-white':top10Active}"
          class="flex items-center w-full px-6 h-full cursor-pointer"
          @click="activeTab('top10')"
        >
          <base-icon name="podium" size="lg" />
          <span class="ml-3">
            <h3 class="leading-tight font-bold">{{ $t('app.top10') }}</h3>
            <span class="opacity-60 text-sm font-semibold ">{{ lastDate }}</span>
          </span>
        </li>
        <li
          :class="{'bg-secondary text-white':top20Active}"
          class="flex items-center w-full px-6 h-full cursor-pointer"
          @click="activeTab('top20')"
        >
          <base-icon name="barchart" size="lg" />
          <span class="ml-3">
            <h3 class="leading-tight font-bold">{{ $t('app.top20') }}</h3>
            <span class="opacity-60 text-sm font-semibold ">{{ $t('app.branshareLast14days') }}</span>
          </span>
        </li>
      </ul>
    </nav>
    <div
      v-if="tabActive === 'top10'"
      class="overflow-y-auto"
    >
      <div class="w-full md:w-11/12 md:mx-auto 2xl:w-full 2xl:pl-10 2xl:pr-6 py-4">
        <template v-if="(selectedTop10 && selectedTop10.length) || !touch">
          <table-top-ten
            v-if="view === 'table'"
            :data="selectedTop10"
            :date="lastDateSimple"
            :loading="loading"
            @selected="onSelectProduct"
          />
          <top-ten-card-view
            v-else
            :data="selectedTop10"
            :date="lastDateSimple"
            :loading="loading"
            @selected="onSelectProduct"
          />
        </template>
        <template v-else>
          <base-not-found />
        </template>
      </div>
    </div>
    <template v-else>
      <app-highchart v-if="selectedTop20"
        :ref="selectedTop20.ref"
        :chart="selectedTop20"
        :categories="selectedTop20Brands"
        :colors="selectedTop20Colors"
        :loading="loadingChart"
        @changeActiveCategories="changeActiveCategories"
        customChartClass="h-full"
        customClass="pb-3">
      </app-highchart>
      <template v-else>
        <base-not-found />
      </template>
    </template>

    <others-modal v-if="othersModalData"
      :isOpen="isOpenOthersModal"
      :title="title"
      :categories="totalCategories"
      :colors="totalColors"
      :data="othersModalData"
      @close="closeOthersModal"
    ></others-modal>

    <product-detail-panel
      v-if="productDetail"
      :key="productDetailKey"
      :panelOpen="true"
      :data="productDetail"
      @close="closeProductPanel"
    />
  </div>
</template>

<script>
import { getTop10 } from '@/services/dashboard/getTop10'
import { getBrandShareTop20 } from '@/services/dashboard/getBrandShareTop20'
import { transformTop20 } from '@/utils/transformDataToHighcharts'
import { getColors } from '@/utils/colors'
import TableTopTen from '@/components/tables/TableTopTen.vue'
import TopTenCardView from '@/components/TopTenCardView.vue'
import ProductDetailPanel from '@/components/ProductDetailPanel.vue'
import AppHighchart from '@/components/charts/AppHighchart'
import OthersModal from '@/components/modalViews/OthersModal'
import moment from 'moment'
import { LIMIT, OTHER_COLOR } from '@/config/general'
import csvMixin from '@/mixins/csvMixin'
import { getCurrencyFormat } from '@/i18n/numbers'
import { deepClone } from '@/utils/general'

const { parse } = require('json2csv')

export default {
  name: 'Dashboard',
  components: {
    TableTopTen,
    TopTenCardView,
    ProductDetailPanel,
    AppHighchart,
    OthersModal
  },
  mixins: [csvMixin],
  data: function () {
    return {
      loadingChart: false,
      hasOthers: false,
      isOpenOthersModal: false,
      othersModalData: null,
      touch: false,
      productDetail: null,
      activeCategories: [],
      top10: [],
      top20: [],
      top20Brands: [],
      colors: [],
      tabActive: 'top10',
      selectedTop20: null,
      selectedTop20Brands: [],
      selectedTop20Colors: [],
      totalCategories: [],
      totalColors: [],
      totalData: [],
      loading: false,
      csvOptions: {
        delimiter: ';',
        fields: ['position', 'brandname', 'pvp', 'positionchange', 'pvpchange', 'link', 'imageurl']
      },
      csvLinks: [
        { ref: 'top10', title: this.$t('app.top10'), subtitle: this.$t('csv.file') },
        { ref: 'top20', title: this.$t('app.top20'), subtitle: this.$t('csv.file') }
      ]
    }
  },
  computed: {
    countryCode: function () {
      return this.$store.getters['filters/countryCode']
    },
    lastDate: function () {
      const date = this.$store.getters['filters/general']?.dateRange.end

      return date ? moment(date, 'YYYY-MM-DD').format('D MMM, YY') : ''
    },
    lastDateSimple: function () {
      const date = this.$store.getters['filters/general']?.dateRange.end

      return date ? moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''
    },
    top10Active: function () {
      return this.tabActive === 'top10'
    },
    top20Active: function () {
      return this.tabActive === 'top20'
    },
    view: function () {
      return this.$store.getters['filters/view']
    },
    groupId: function () {
      return this.$store.getters['filters/groupId']
    },
    categoryId: function () {
      return this.$store.getters['filters/categoryId']
    },
    site: function () {
      return this.$store.getters['filters/site']
    },
    brand: function () {
      return this.$store.getters['auth/user']?.nombreMarca
    },
    color: function () {
      return this.$store.getters['auth/user']?.color
    },
    selectedTop10: function () {
      let selectedTop10 = this.top10.find(item => item.sitio === this.site)
      selectedTop10 = selectedTop10 ? selectedTop10.listaRankintTop10 : []
      selectedTop10 = selectedTop10.length > 10 ? selectedTop10.slice(0, 10) : selectedTop10

      return selectedTop10.map(item => {
        item.idPrice = item.idprice
        item.imageUrl = item.imageurl
        return item
      })
    },
    csvChangeCounter: function () {
      return this.$store.getters['app/csvCounter']
    },
    csvRef: function () {
      return this.$store.getters['app/csvOptions'].ref
    },
    csvData: function () {
      let data = [...this.selectedTop10]
      data = data.map(item => {
        const i = { ...item }
        i.pvp = item.pvp ? getCurrencyFormat(item.pvp, this.countryCode) : null
        i.pvpchange = item.pvpchange ? getCurrencyFormat(item.pvpchange, this.countryCode) : null
        return i
      })
      return parse(data, this.csvOptions)
    },
    productDetailKey: function () {
      return this.productDetail ? this.productDetail.idPrice : null
    },
    actualCategories: function () {
      if (this.top20Active) {
        return this.activeCategories
      }
      return this.selectedTop20.options.series.map(serie => serie.name)
    },
    title: function () {
      return this.$t('app.top20') + ' - ' + this.$t('app.others')
    }
  },
  created: async function () {
    this.$store.commit('app/setCsvLinks', this.csvLinks)
    this.$store.commit('filters/setHiddenView', false)
    try {
      this.$store.commit('app/setLoading', true)
      await this.downloadingData()
    } catch (error) {
      this.doFeedback()
    } finally {
      this.$store.commit('app/setLoading', false)
    }
  },
  methods: {
    activeTab: function (val) {
      this.tabActive = val
      if (val === 'top10') {
        this.$store.commit('filters/setHiddenView', false)
      } else {
        this.$store.commit('filters/setHiddenView', true)
      }
    },
    downloadingData: async function () {
      try {
        if (this.top20Active) {
          this.$store.commit('app/setLoading', true)
        } else {
          this.loading = true
        }
        await Promise.all([
          this.fetchTop10(),
          this.fetchTop20()
        ])
      } catch (error) {
        this.doFeedback()
      } finally {
        this.touch = true
        this.loading = false
        this.$store.commit('app/setLoading', false)
      }
    },
    fetchTop10: async function () {
      const groupId = this.groupId
      const categoryId = this.categoryId
      const data = await getTop10(groupId, categoryId)

      this.top10 = data
    },
    fetchTop20: async function () {
      const groupId = this.groupId
      const categoryId = this.categoryId
      const data = await getBrandShareTop20(groupId, categoryId)

      const top20Data = transformTop20(data, LIMIT)
      const top20TotalData = transformTop20(data)
      let top20 = top20Data.charts
      const top20Brands = top20Data.brands
      const totalCategories = top20Data.totalCategories

      const othersLabel = this.$t('app.others')
      if (top20Brands.includes(othersLabel)) {
        this.hasOthers = true
      } else {
        this.hasOthers = false
      }

      const totalColors = this.getColors(totalCategories)
      const colors = this.getCustomColors(top20Brands, totalCategories, totalColors)

      top20 = this.setChartsColors(top20, top20Brands, colors)
      top20 = this.setSeriesEvents(top20)

      this.colors = colors
      this.totalColors = totalColors
      this.top20 = top20
      this.top20Brands = top20Brands
      this.totalCategories = totalCategories
      this.totalData = top20TotalData.charts

      this.updateSelectedTop20()
    },
    updateSelectedTop20: function () {
      this.loadingChart = true
      let selectedTop20 = this.top20.find(item => item.site === this.site)

      if (selectedTop20) {
        const selectedTop20Brands = this.getSelectedTop20Brands(selectedTop20)
        // const selectedTop20Colors = this.getSelectedTop20Colors(selectedTop20Brands)
        const selectedTop20Colors = this.getCustomColors(selectedTop20Brands, this.totalCategories, this.totalColors)
        selectedTop20 = this.setSelectedTop20Colors(selectedTop20, selectedTop20Colors)

        this.selectedTop20Brands = selectedTop20Brands
        this.selectedTop20Colors = selectedTop20Colors
        this.selectedTop20 = selectedTop20
      } else {
        this.selectedTop20 = null
      }
      setTimeout(() => {
        this.loadingChart = false
      }, 30)
    },
    setSeriesEvents: function (data) {
      if (this.hasOthers) {
        const that = this
        for (const chart of data) {
          chart.options.plotOptions.series.events = {
            click: function (e) {
              if (this.name === that.$t('app.others')) {
                const x = e.point.category
                that.openOthersModal(x)
              }
            }
          }

          chart.options.chart.events = {
            load: function () {
              const serie = this.series.find(s => s.name === that.$t('app.others'))
              if (serie) {
                serie.data.forEach(point => {
                  if (point.graphic) {
                    point.graphic.attr({
                      cursor: 'pointer'
                    })
                  }
                })
              }
            }
          }
        }
      }

      return data
    },
    getColors: function (brands) {
      const length = brands.length
      const colors = getColors(length)
      const othersLabel = this.$t('app.others')

      if (brands.includes(othersLabel)) {
        const position = brands.findIndex(brand => brand === othersLabel)
        colors[position] = OTHER_COLOR
      }

      const color = this.color
      const brand = this.brand
      if (brands.includes(brand)) {
        const position = brands.findIndex(item => item === brand)
        colors[position] = color
      }

      return colors
    },
    getCustomColors: function (categories, totalCategories, totalColors) {
      return categories.map(category => {
        const index = totalCategories.findIndex(c => c === category)
        return totalColors[index]
      })
    },
    setChartsColors: function (charts, brands, colors) {
      for (const chart of charts) {
        const seriesBrands = chart.series.map(serie => serie.name)
        const c = this.getColorsByBrands(seriesBrands, brands, colors)
        chart.options.colors = c
      }

      return charts
    },
    getColorsByBrands: function (seriesBrands, brands, colors) {
      return seriesBrands.map(brand => {
        const position = brands.findIndex(b => b === brand)
        return colors[position]
      })
    },
    getSelectedTop20Brands: function (selectedTop20) {
      if (selectedTop20) {
        let brands = selectedTop20.series.map(serie => serie.name)

        const othersLabel = this.$t('app.others')
        const other = brands.find(brand => brand === othersLabel)
        if (other) {
          brands = brands.filter(brand => brand !== othersLabel)
          brands.push(other)
        }

        return brands
      }
      return []
    },
    getSelectedTop20Colors: function (selectedTop20Brands) {
      const length = selectedTop20Brands.length
      const colors = getColors(length - 1)
      colors.push(OTHER_COLOR)

      const color = this.color
      const brand = this.brand
      if (selectedTop20Brands.includes(brand)) {
        const position = selectedTop20Brands.findIndex(item => item === brand)
        colors[position] = color
      }

      return colors
    },
    setSelectedTop20Colors: function (selectedTop20, selectedTop20Colors) {
      selectedTop20.options.colors = selectedTop20Colors
      return selectedTop20
    },
    onSelectProduct: function (product) {
      this.productDetail = product
    },
    closeProductPanel: function () {
      this.productDetail = null
    },
    changeActiveCategories: function (categories) {
      this.activeCategories = categories
    },
    openOthersModal: function (category) {
      const selectedTotalData = this.totalData.find(item => item.site === this.selectedTop20.site)
      const series = deepClone(this.selectedTop20.series)
      const totalSeries = deepClone(selectedTotalData.series)
      const options = deepClone(this.selectedTop20.options)
      const othersOptions = deepClone(selectedTotalData.options)
      const categories = options.xAxis.categories
      const categoryIndex = categories.findIndex(c => c === category)
      const othersCategories = othersOptions.xAxis.categories
      const othersCategoryIndex = othersCategories.findIndex(c => c === category)
      options.xAxis.categories = [category]
      othersOptions.xAxis.categories = [category]

      const serie = series.map(s => {
        s.data = [s.data[categoryIndex]]
        return s
      })

      const otherSerie = this.getOthersSerie(totalSeries, othersCategoryIndex, serie.filter(s => s.data[0]).map(s => s.name))
      const auxCategories = otherSerie.map(s => s.name)
      const colors = this.getColorsByBrands(auxCategories, this.totalCategories, this.totalColors)
      othersOptions.colors = colors
      const max = otherSerie.map(s => s.data[0] ? s.data[0] : 0).reduce((a, b) => a + b, 0)
      othersOptions.yAxis.max = max
      othersOptions.yAxis.tickPositioner = function () {
        const positions = this.tickPositions
        if (positions.length) {
          positions[positions.length - 1] = max
        }
        return positions
      }

      options.series = serie
      othersOptions.series = otherSerie

      this.othersModalData = [
        {
          ref: 'general',
          options: options,
          series: serie
        },
        {
          ref: 'others',
          options: othersOptions,
          series: otherSerie
        }
      ]
      this.isOpenOthersModal = true
    },
    getOthersSerie: function (series, categoryIndex, categories) {
      const otherSerie = series.filter(s => !categories.includes(s.name)).map(s => {
        s.data = [s.data[categoryIndex]]
        return s
      })

      return otherSerie.sort((a, b) => b.data[0] - a.data[0])
    },
    closeOthersModal: function () {
      this.isOpenOthersModal = false
    }
  },
  watch: {
    categoryId: function () {
      this.downloadingData()
    },
    site: function () {
      this.updateSelectedTop20()
    },
    csvChangeCounter: function () {
      const ref = this.csvRef
      if (ref === 'top10') {
        this.downloadCsv(this.csvData, 'top10')
      } else if (ref === 'top20') {
        const data = this.getCSVFromColumnChart(this.selectedTop20.options, 'percent')
        this.downloadCsv(data, 'top20')
      }
    }
  }
}
</script>
