<template>
  <tr :class="{'selected':value}">
    <td>
      <span class="block relative">
        <b
          v-if="setPositionIcon"
          :class="setPositionStatus"
          class="absolute -top-1 right-0 w-4 h-4 bg-white flex items-center justify-center z-10 rounded-full">
          <base-icon :name="setPositionIcon" />
        </b>
        <span
          :class="setPostionStatusNumber"
          class="h-9 w-9 flex items-center justify-center rounded-full font-bold text-lg">{{ position }}</span>
      </span>
    </td>
    <td><span class="uppercase text-xs font-medium">{{ row.brandname }}</span></td>
    <td class="tr-truncate font-semibold" @click="onClick(row)">
      <span>{{ row.name }}</span>
      <a
        :href="row.link"
        target="_blank"
        alt="Ficha del producto en su web de venta. Se abrirá en página a parte"
        class="tr-external-link"
        v-tooltip="'Ver ficha - Enlace Externo'"
      >
        <base-icon
          name="external"
          size="xs"
        />
      </a>
    </td>
    <td>
      <span :class="setPriceStatus" class="flex items-center justify-center font-semibold h-7 rounded-lg px-2">
        <base-icon v-if="setPriceIcon" :name="setPriceIcon" size="xs" />
        <span class="font-mono">{{ row.pvp | money(countryCode) }}</span>
      </span>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'TableRowTopTen',
  props: {
    row: {
      type: [Object],
      required: true
    },
    date: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      value: false,
      isSelected: false
    }
  },
  computed: {
    countryCode: function () {
      return this.$store.getters['filters/countryCode']
    },
    position: function () {
      return this.row.position
    },
    priceVariation: function () {
      return this.row.pvpchange
    },
    setPriceStatus: function () {
      if (this.row.pvpchange > 0) {
        return 'bg-success-50 text-success'
      } else if (this.row.pvpchange < 0) {
        return 'bg-danger-50 text-danger'
      } else {
        return 'bg-secondary-50'
      }
    },
    setPriceIcon: function () {
      if (this.row.pvpchange > 0) {
        return 'priceup'
      } else if (this.row.pvpchange < 0) {
        return 'pricedown'
      } else {
        return false
      }
    },
    setPostionStatusNumber: function () {
      if (this.row.positionchange < 0) {
        return 'bg-success-50 text-success'
      } else if (this.row.positionchange > 0) {
        return 'bg-danger-50 text-danger'
      } else {
        return 'bg-secondary-50'
      }
    },
    setPositionStatus: function () {
      if (this.row.positionchange < 0) {
        return 'text-success'
      } else if (this.row.positionchange > 0) {
        return 'text-danger'
      } else {
        return 'text-primary'
      }
    },
    setPositionIcon: function () {
      const enteredAt = this.row.enteredat
      const createdAt = this.row.createdAt
      const date = this.date

      if (createdAt === date) {
        return 'nentry'
      }
      if (enteredAt === date) {
        return 'rentry'
      }
      if (this.row.positionchange < 0) {
        return 'posup'
      }
      if (this.row.positionchange > 0) {
        return 'posdown'
      }

      return false
    }
  },
  methods: {
    onClick: function (product) {
      this.$emit('click', product)
    }
  }
}
</script>

<style lang="scss" scoped>
.progress {
  position: absolute;
  left: 4px;
  width: 100%;
  height: 32px;
  margin-top: -26px;
  z-index: 1;
  background: #d1e1ff;
  border-radius: 6px;
}

tbody {
  tr {
    position: relative;
    z-index: 5;
    &:not(.selected):hover {
      transition: all 0.3s;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0px 2px 10px rgba(#086dff, 0.2);
      position: relative;
      z-index: 10;
    }
    td {
      @apply text-sm;
      background-color: white;
      padding: 6px 8px;
      border-width: 0px;
      border-top-width: 2px;
      border-bottom-width: 2px;
      border-color: white;
      border-style: solid;
      white-space: nowrap;
      &:first-child {
        text-align: center;
        border-left-width: 2px;
        border-color: white;
      }
      &:last-child {
        border-right-width: 2px;
        border-color: white;
      }
      span {
        position: relative;
        z-index: 5;
        &.check-toggle {
          visibility: hidden;
        }
        &.bubble-highlight {
          // top: 2px;
          @apply bg-primary-50;
          svg {
            position: relative;
            // bottom: 2px;
          }
        }
      }
    }
    &:hover {
      td {
        @apply border-primary;
        span {
          &.check-toggle {
            visibility: visible;
          }
        }
      }
    }
    &.selected {
      td {
        border-color: var(--primary-800);
        @apply text-white bg-secondary;
        span {
          &.bubble-highlight {
            @apply bg-secondary-900 text-secondary-100;
          }
        }
      }
      .progress {
        @apply bg-primary;
      }
    }
  }
}

tbody tr:first-child,
tbody td:first-child {
  border-top-left-radius: 8px;
}
tbody tr:first-child,
tbody td:last-child {
  border-top-right-radius: 8px;
}
tbody tr:last-child,
tbody td:first-child {
  border-bottom-left-radius: 8px;
}
tbody tr:last-child,
tbody td:last-child {
  border-bottom-right-radius: 8px;
}

.tr-external-link {
  opacity: 0;
  position: absolute;
  top: 50%;
  background: var(--primary-700);
  border-radius: 50%;
  transform: translateY(-50%);
  right: -10px;
  color: white;
  transition: right 0.2s, opacity 0.3s;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.tr-truncate {
  position: relative;
  white-space: nowrap;
  width: 100%;
  max-width:1px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  &:hover {
    .tr-external-link {
      opacity: 1;
      right: 4px;
      &:hover {
        background: darken(#086dff, 10%);
      }
    }
  }
}

.check {
  width: 40px;
}
.position {
  max-width: 80px;
}
.price {
  max-width: 120px;
}

/* Styles for the hover state appearance of the custom checkbox */
input[type="checkbox"].check-custom:hover ~ .check-toggle {
  @apply border-2 border-solid border-primary;
}

/* Styles for the focus state appearance of the custom checkbox */
input[type="checkbox"].check-custom:focus ~ .check-toggle {
  border-color: #b0d5ff;
  box-shadow: 0 0 0 2px rgba(23, 133, 255, 0.25);
}

input[type="checkbox"].check-custom {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

input[type="checkbox"].check-custom ~ .check-toggle {
  width: 1.1rem;
  height: 1.1rem;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  cursor: pointer;
  background: white;
  @apply border-2 border-solid border-primary;
}

/* Styles for the hover state appearance of the custom checkbox */
input[type="checkbox"].check-custom:hover ~ .check-toggle {
  @apply border-2 border-solid border-primary-500;
}

/* Styles for the checked state appearance of the custom checkbox */
input[type="checkbox"].check-custom:checked ~ .check-toggle {
  @apply bg-primary border-2 border-solid border-primary;
  background: var(--primary-700)
    url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cG9seWxpbmUgcG9pbnRzPSIyMCA2IDkgMTcgNCAxMiI+PC9wb2x5bGluZT48L3N2Zz4=)
    center no-repeat;
  background-size: 80%;
}

input[type="checkbox"].check-custom:checked ~ .check-toggle {
  visibility: visible;
}
</style>
