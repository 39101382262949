<template>
  <panel-layout :isOpen="true" @closePanel="closePanel">
    <template slot="header">
      <div class="flex relative bg-white shadow pt-8">
        <div class="w-28 p-2 flex-shrink-0 self-start">
          <img :src="data.imageUrl" class="object-cover w-full" :alt="data.name">
        </div>
        <div class="ml-2 pr-3 overflow-hidden">
          <span class="text-xs uppercase font-medium truncate">{{ brandname }}</span>
          <h3 class="leading-tight font-semibold">{{ data.name }}</h3>
          <div class="flex items-center space-x-2 text-xs mt-2 pb-2 overflow-x-auto">
            <span v-if="data.dimension1" class="flex items-center justify-center rounded-full px-4 h-6 bg-secondary-100 whitespace-nowrap">{{ data.dimension1 }}</span>
            <span v-if="data.dimension2" class="flex items-center justify-center rounded-full px-4 h-6 bg-secondary-100 whitespace-nowrap">{{ data.dimension2 }}</span>
            <span v-if="data.dimension3" class="flex items-center justify-center rounded-full px-4 h-6 bg-secondary-100 whitespace-nowrap">{{ data.dimension3 }}</span>
            <span v-if="data.dimension4" class="flex items-center justify-center rounded-full px-4 h-6 bg-secondary-100 whitespace-nowrap">{{ data.dimension4 }}</span>
            <span v-if="data.dimension5" class="flex items-center justify-center rounded-full px-4 h-6 bg-secondary-100 whitespace-nowrap">{{ data.dimension5 }}</span>
          </div>
        </div>
        <span class="absolute top-2 right-2 cursor-pointer w-8 h-8 rounded-full flex items-center justify-center bg-primary-50" @click="closePanel">
          <base-icon name="cross" size="xs"/>
        </span>
      </div>
    </template>
    <template slot="main">
      <div class="p-1 pt-4">
        <a class="flex justify-between place-items-center p-3 px-4 bg-primary hover:bg-primary-600 text-white hover:text-white rounded-lg" target="_blank" :href="data.link">
          <span>
            <span class="font-semibold">{{ site }}</span>
            <span class="block text-sm opacity-60">{{ $t('app.seeSheet') }}</span>
          </span>
          <base-icon name="external" custom="text-white" />
        </a>

        <div class="grid grid-cols-2 gap-2 mt-2">
          <div class="bg-primary-50 rounded-lg shadow px-3 py-1">
            <header class="block mt-2 text-xs leading-tight mb-1">
              <span class="uppercase font-semibold">{{ $t('app.position') }}</span>
              <span class="opacity-75 block">{{ $tc('app.averageDaysCounter', average) }}</span>
            </header>
            <p class="text-right"><span class="font-bold text-xl">{{ averagePosition | number(0, 2, countryCode) }}</span></p>
          </div>
          <div class="bg-primary-50 rounded-lg shadow px-3 py-1">
            <header class="block mt-2 text-xs leading-tight mb-1">
              <span class="uppercase font-semibold">{{ $t('app.price') }}</span>
              <span class="opacity-75 block">{{ $tc('app.averageDaysCounter', average) }}</span>
            </header>
            <p class="text-right"><span class="font-bold text-xl">{{ averagePrice | money(countryCode) }}</span></p>
          </div>
        </div>

        <div class="flex flex-col h-65 flex-1 rounded-lg bg-primary-50 mt-2 overflow-hidden">
          <header class="flex-shrink-0 p-6 pb-0 text-xs">
            <h4 class="uppercase font-semibold">{{ $t('app.positionEvolution') }}</h4>
            <span class="font-medium opacity-70">{{ $t('app.fromTo', { start: transformDate(startDate), end: transformDate(endDate) }) }}</span>
          </header>
          <app-highchart v-if="positionData" class="hidden-apexcharts-tooltip-text-label h-full" customChartClass="h-full" :chart="positionData" :categories="[]" :colors="[]" :disable-legend="true" :tight="true" :enableEvents="false"></app-highchart>
          <base-not-found-lite customImgClass="w-12 mb-1" v-else />
        </div>
        <div class="flex flex-col h-65 rounded-lg bg-primary-50 mt-2 overflow-hidden">
          <header class="flex-shrink-0 p-6 pb-0 text-xs">
            <h4 class="uppercase font-semibold">{{ $t('app.priceEvolution') }}</h4>
            <span class="font-medium opacity-70">{{ $t('app.fromTo', { start: transformDate(startDate), end: transformDate(endDate) }) }}</span>
          </header>
          <app-highchart v-if="priceData" class="hidden-apexcharts-tooltip-text-label h-full" customChartClass="h-full" :chart="priceData" :categories="[]" :colors="[]" :disable-legend="true" :tight="true" :enableEvents="false"></app-highchart>
          <base-not-found-lite customImgClass="w-12 mb-1" v-else />
        </div>

      </div>
    </template>
  </panel-layout>
</template>
<script>
import PanelLayout from '@/layouts/PanelLayout'
import moment from 'moment'
import { getCompetition } from '@/services/visual-rank/getCompetition'
import { transformPriceCompetition, transformPositionCompetition } from '@/utils/transformDataToHighcharts'
import AppHighchart from '@/components/charts/AppHighchart'

export default {
  name: 'product-detail-panel',
  components: {
    PanelLayout,
    AppHighchart
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      priceData: null,
      positionData: null
    }
  },
  computed: {
    countryCode: function () {
      return this.$store.getters['filters/countryCode']
    },
    site: function () {
      return this.$store.getters['filters/site']
    },
    startDate: function () {
      return this.$store.getters['filters/startDate']
    },
    endDate: function () {
      return this.$store.getters['filters/endDate']
    },
    average: function () {
      return moment(this.endDate).diff(moment(this.startDate), 'days') + 1
    },
    brandname: function () {
      return this.data.brandName || this.data.brandname
    },
    averagePosition: function () {
      if (this.positionData) {
        const serie = this.positionData.series[0]
        if (serie && serie.data.length) {
          const acc = serie.data.reduce((acc, cur) => acc + cur, 0)
          return acc / serie.data.length
        }
      }
      return ''
    },
    averagePrice: function () {
      if (this.priceData) {
        const serie = this.priceData.series[0]
        if (serie && serie.data.length) {
          const data = serie.data.filter((value) => !!value)
          console.log(data)
          const acc = data.reduce((acc, cur) => acc + cur, 0)
          return acc / data.length
        }
      }
      return ''
    }
  },
  created: function () {
    this.downloadingData()
  },
  methods: {
    closePanel: function () {
      this.$emit('close')
    },
    downloadingData: async function () {
      try {
        this.$store.commit('app/setLoading', true)
        await this.fetchVisualRank()
      } catch (error) {
        this.doFeedback()
      } finally {
        this.$store.commit('app/setLoading', false)
      }
    },
    fetchVisualRank: async function () {
      const startDate = moment(this.startDate).format('DD-MM-YYYY')
      const endDate = moment(this.endDate).format('DD-MM-YYYY')
      const body = {
        listaPrecios: [this.data.idPrice]
      }

      const data = await getCompetition(startDate, endDate, body)

      const countryCode = this.countryCode

      const transformPriceData = transformPriceCompetition(data, countryCode)
      const transformPositionData = transformPositionCompetition(data)

      const priceData = transformPriceData.chart
      const positionData = transformPositionData.chart

      this.priceData = priceData
      this.positionData = positionData
    },
    transformDate: function (value, format = 'D MMM, YY') {
      return value ? moment(value, 'YYYY-MM-DD').format(format) : ''
    }
  },
  filters: {
    date: function (value, format = 'D MMM, YY') {
      return value ? moment(value, 'YYYY-MM-DD').format(format) : ''
    }
  },
  watch: {
    data: function () {
      this.downloadingData()
    }
  }
}
</script>
